## REPORT A VIOLATION <br />(with or without your name)

Did you experience something awry or questionable at a Toronto JS event, or on our discussion channels? Feel free to direct others to our [Code Of Conduct](https://torontojs.com/p/code_of_conduct). Our organizers, moderators and volunteers want to maintain a welcoming environment free of abuse, harassment, or discrimination.

Please provide the following information:

- the time and place the harassment took place
- the member(s) involved in the event
- any surrounding details you think are relevant to the report

<form
  action="https://formspree.io/f/mayvyvzk"
  method="POST"
  width="100%"
>
  <label htmlFor="_replyto">
    Email
    <input type="email" name="_replyto"/>
  </label>
  <br/>
  <label>
    Description
    <br/>
    <textarea name="message" rows="5"></textarea>
  </label>
  <br/>
  <button type="submit">Send</button>
</form>
