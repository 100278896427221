## Best of TorontoJS

### dusty

![bdsm-ai](https://i.imgur.com/vX1FOhm.png)

### jonlim (with the layup from Jen Saxena)

![pooetry](https://i.imgur.com/wVrKOdj.png)

### haegin

![squirm my fingers inside](https://i.imgur.com/rVy1zQO.png)

### jonlim

![ruby on braille](https://i.imgur.com/qoUdh8r.png)

### kieran

![fake coffeescript](https://i.imgur.com/kJclhm0.png)
