const _temp0 = require("./avatars/akshat.jpg");
const _temp1 = require("./avatars/aman.jpg");
const _temp2 = require("./avatars/andrew.jpg");
const _temp3 = require("./avatars/ankur.jpg");
const _temp4 = require("./avatars/brandon.jpg");
const _temp5 = require("./avatars/cam.jpg");
const _temp6 = require("./avatars/christine.jpg");
const _temp7 = require("./avatars/connor.jpg");
const _temp8 = require("./avatars/daleee.jpg");
const _temp9 = require("./avatars/dann.jpg");
const _temp10 = require("./avatars/danny.jpg");
const _temp11 = require("./avatars/david.jpg");
const _temp12 = require("./avatars/divish.jpg");
const _temp13 = require("./avatars/drey.jpg");
const _temp14 = require("./avatars/elizabeth.jpg");
const _temp15 = require("./avatars/evert.jpg");
const _temp16 = require("./avatars/gabriel.jpg");
const _temp17 = require("./avatars/jack.jpg");
const _temp18 = require("./avatars/jack_z.jpg");
const _temp19 = require("./avatars/jen.jpg");
const _temp20 = require("./avatars/jingru.jpg");
const _temp21 = require("./avatars/kaoru.jpg");
const _temp22 = require("./avatars/kareem.jpg");
const _temp23 = require("./avatars/ken.jpg");
const _temp24 = require("./avatars/kieran.jpg");
const _temp25 = require("./avatars/lamisa.jpg");
const _temp26 = require("./avatars/liz.jpg");
const _temp27 = require("./avatars/marco.jpg");
const _temp28 = require("./avatars/matt.jpg");
const _temp29 = require("./avatars/mattMihok.jpg");
const _temp30 = require("./avatars/metkel.jpg");
const _temp31 = require("./avatars/michael.jpg");
const _temp32 = require("./avatars/mike.jpg");
const _temp33 = require("./avatars/nicole.jpg");
const _temp34 = require("./avatars/niya.jpg");
const _temp35 = require("./avatars/rachael.jpg");
const _temp36 = require("./avatars/rajat.jpg");
const _temp37 = require("./avatars/sami.jpg");
const _temp38 = require("./avatars/sherry.jpg");
const _temp39 = require("./avatars/simon.jpg");
const _temp40 = require("./avatars/taz.jpg");
const _temp41 = require("./avatars/tehseen.jpg");
const _temp42 = require("./avatars/yuri.jpg");
module.exports = {
  "akshat": _temp0,
  "aman": _temp1,
  "andrew": _temp2,
  "ankur": _temp3,
  "brandon": _temp4,
  "cam": _temp5,
  "christine": _temp6,
  "connor": _temp7,
  "daleee": _temp8,
  "dann": _temp9,
  "danny": _temp10,
  "david": _temp11,
  "divish": _temp12,
  "drey": _temp13,
  "elizabeth": _temp14,
  "evert": _temp15,
  "gabriel": _temp16,
  "jack": _temp17,
  "jack_z": _temp18,
  "jen": _temp19,
  "jingru": _temp20,
  "kaoru": _temp21,
  "kareem": _temp22,
  "ken": _temp23,
  "kieran": _temp24,
  "lamisa": _temp25,
  "liz": _temp26,
  "marco": _temp27,
  "matt": _temp28,
  "mattMihok": _temp29,
  "metkel": _temp30,
  "michael": _temp31,
  "mike": _temp32,
  "nicole": _temp33,
  "niya": _temp34,
  "rachael": _temp35,
  "rajat": _temp36,
  "sami": _temp37,
  "sherry": _temp38,
  "simon": _temp39,
  "taz": _temp40,
  "tehseen": _temp41,
  "yuri": _temp42
}